import { LinkButtonStyled } from "../../components/GeneralComponents/LinkButtonStyled";

// Main component
export default function NotFound() {
    return (
        <div className="d-flex justify-content-center align-items-center py-5 my-5">
            <div className="d-flex flex-column align-items-center">
                <h1 className="display-4 mb-5">404: Not Found</h1>
                <LinkButtonStyled link="/">Back to Home Page</LinkButtonStyled>
            </div>
        </div>
    );
}
