import { useForm } from "react-hook-form";
import { Container } from "react-bootstrap";
import { LinkButtonStyled } from "./GeneralComponents/LinkButtonStyled";
import { regexPatterns } from "../helpers/validationRegex";
import { useState } from "react";
import { toast } from "react-toastify";

export default function CallToActionAndNewsletter({ title, description, nameLink, link, smallText, addClass, variant = "default" }) {
    const API_RADIO_ANALYZER = process.env.REACT_APP_API_RADIO_ANALYZER;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [email, setEmail] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async () => {
        setIsSubmitting(true);
        setSuccessMessage("");
        try {
            const response = await fetch(`${API_RADIO_ANALYZER}/subscribe/?email=${email}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            setSuccessMessage("Your request was successfully sent!");
            setEmail("");
        } catch (error) {
            toast.error(error.message || "A network error occurred.");
        } finally {
            setIsSubmitting(false);
            setTimeout(() => {
                setSuccessMessage("");
            }, 3000);
        }
    };

    switch (variant) {
        case "default":
            return (
                <div className={`px-4 py-5 text-center rounded-2 CallToActionAndNewsletter ${addClass}`}>
                    <h2 className="text-uppercase">{title}</h2>
                    <div className="d-flex justify-content-center">
                        <span className="d-block">{description}</span>
                    </div>
                    <div className="d-flex justify-content-center mt-3">
                        <LinkButtonStyled link={link}>{nameLink}</LinkButtonStyled>
                    </div>
                    {smallText ? <aside className="fs-10 pt-3">{smallText}</aside> : null}
                </div>
            );

        case "blue":
            return (
                <div className={`p-5 my-5 bg-blue-primary text-white w-100 ${addClass}`}>
                    <Container
                        className={`d-flex flex-column flex-xl-row align-items-center ${nameLink ? "justify-content-between" : "justify-content-center"}`}>
                        <div className="d-flex flex-column text-center text-xl-start">
                            <h2 className="text-uppercase">{title}</h2>
                            {description ? <span className="d-block">{description}</span> : null}
                        </div>
                        {nameLink ? (
                            <LinkButtonStyled variant="light" link={link} addClass="mt-4 mt-xl-0 fw-bold px-4 py-2 fs-5 text-center">
                                {nameLink}
                            </LinkButtonStyled>
                        ) : null}
                    </Container>
                </div>
            );

        case "newsletter":
            return (
                <div className={`p-5 my-5 bg-blue-primary text-white w-100 ${addClass}`}>
                    <Container className={`d-flex flex-column`}>
                        <div className="d-flex flex-column">
                            <h2 className="text-uppercase">{title}</h2>
                            {description ? <span className="d-block">{description}</span> : null}
                        </div>

                        <div className="d-flex flex-column mt-3">
                            <form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column flex-md-row mt-3 mt-md-0 align-items-md-start">
                                <div className={`${errors.email ? "min-h-70" : ""} w-100-to-lg-50`}>
                                    <input
                                        placeholder="Enter email address"
                                        type="email"
                                        {...register("email", {
                                            required: "Email is required.",
                                            pattern: {
                                                value: regexPatterns.email,
                                                message: "Email is not valid.",
                                            },
                                        })}
                                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                        aria-invalid={errors.email ? "true" : "false"}
                                        id="email"
                                        onChange={e => setEmail(e.target.value)}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-warning fw-bold text-uppercase newsletter-btn ms-md-2 mt-2 mt-md-0 text-nowrap"
                                    disabled={successMessage}>
                                    {isSubmitting ? "Sending..." : "sign up to the newsletter"}
                                </button>
                            </form>
                            {successMessage ? (
                                <aside className="mt-3 text-center">
                                    <div className="alert alert-success m-0">{successMessage}</div>
                                </aside>
                            ) : (
                                ""
                            )}
                        </div>
                    </Container>
                </div>
            );

        default:
            return null;
    }
}
