import { useState } from "react";
import { useForm } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { regexPatterns } from "../helpers/validationRegex";
import { getTodayDate } from "../helpers/getTodayDate";
import { Col, Row } from "react-bootstrap";

export const FormOnlineDemo = () => {
    const API_RADIO_ANALYZER = process.env.REACT_APP_API_RADIO_ANALYZER;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm();

    const onSubmit = async data => {
        const payload = {
            name: data.name,
            email: data.email,
            phone_number: data.phone,
            job: data.job,
            company: data.company,
            country: data.country,
            requested_at: data.requestDateRequired,
            requested_to: data.requestDateOptional,
            is_pro: data.is_pro,
            is_mtt: data.is_mtt,
        };

        setIsSubmitting(true);
        setSuccessMessage("");

        try {
            const response = await fetch(`${API_RADIO_ANALYZER}/book_demo/`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                setSuccessMessage("Your request was successfully sent!");
                reset();
            } else {
                throw new Error("An error occurred while sending your request.");
            }
        } catch (error) {
            toast.error(error.message || "A network error occurred.");
        } finally {
            setIsSubmitting(false);
            setTimeout(() => {
                setSuccessMessage("");
            }, 3000);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="container">
            <Row>
                <Col xs={12} className="mb-3">
                    <label>Which demonstration would you like to see?</label>
                    <div className="d-flex flex-column flex-md-row justify-content-between my-2">
                        <div>
                            <input
                                type="checkbox"
                                id="is_pro"
                                {...register("is_pro", {
                                    validate: value => watch("is_pro") || watch("is_mtt") || "At least one option must be selected.",
                                })}
                                className="form-check-input cursor-pointer"
                            />
                            <label htmlFor="is_pro" className="ms-2 fw-bold cursor-pointer">
                                RadioAnalyzer Pro
                            </label>
                        </div>
                        <div>
                            <input
                                type="checkbox"
                                id="is_mtt"
                                {...register("is_mtt", {
                                    validate: value => watch("is_pro") || watch("is_mtt") || "At least one option must be selected.",
                                })}
                                className="form-check-input cursor-pointer"
                            />
                            <label htmlFor="is_mtt" className="ms-2 fw-bold cursor-pointer">
                                Music Test by RadioAnalyzer
                            </label>
                        </div>
                    </div>
                    {(errors.is_pro || errors.is_mtt) && <div className="invalid-feedback d-block">{errors.is_pro?.message || errors.is_mtt?.message}</div>}
                </Col>
            </Row>

            <Row>
                {/* Name */}
                <Col xs={12} className="mb-3">
                    <label htmlFor="name">Name (Required)</label>
                    <input
                        type="text"
                        {...register("name", {
                            required: "Name is required.",
                            pattern: {
                                value: regexPatterns.name,
                                message: "Name is not valid.",
                            },
                        })}
                        className={`form-control ${errors.name ? "is-invalid" : ""}`}
                        aria-invalid={errors.name ? "true" : "false"}
                        id="name"
                    />
                    {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                </Col>

                {/* Email */}
                <Col xs={12} className="mb-3">
                    <label htmlFor="email">Email (Required)</label>
                    <input
                        type="email"
                        {...register("email", {
                            required: "Email is required.",
                            pattern: {
                                value: regexPatterns.email,
                                message: "Email is not valid.",
                            },
                        })}
                        className={`form-control ${errors.email ? "is-invalid" : ""}`}
                        aria-invalid={errors.email ? "true" : "false"}
                        id="email"
                    />
                    {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                </Col>

                {/* Job */}
                <Col xs={12} className="mb-3">
                    <label htmlFor="job">Job</label>
                    <input
                        type="text"
                        {...register("job", {
                            pattern: {
                                value: regexPatterns.job,
                                message: "Job is not valid.",
                            },
                        })}
                        className={`form-control ${errors.job ? "is-invalid" : ""}`}
                        aria-invalid={errors.job ? "true" : "false"}
                        id="job"
                    />
                    {errors.job && <div className="invalid-feedback">{errors.job.message}</div>}
                </Col>
            </Row>

            <Row>
                {/* Company */}
                <Col xs={12} lg={6} className="mb-3">
                    <label htmlFor="company">Company (Required)</label>
                    <input
                        type="text"
                        {...register("company", {
                            required: "Company is required.",
                            pattern: {
                                value: regexPatterns.company,
                                message: "Company is not valid.",
                            },
                        })}
                        id="company"
                        aria-invalid={errors.company ? "true" : "false"}
                        className={`form-control ${errors.company ? "is-invalid" : ""}`}
                    />
                    {errors.company && <div className="invalid-feedback">{errors.company.message}</div>}
                </Col>

                {/* Country */}
                <Col xs={12} lg={6} className="mb-3">
                    <label htmlFor="country">Country (Required)</label>
                    <input
                        type="text"
                        {...register("country", {
                            required: "Country is required.",
                            pattern: {
                                value: regexPatterns.country,
                                message: "Country is not valid.",
                            },
                        })}
                        className={`form-control ${errors.country ? "is-invalid" : ""}`}
                        aria-invalid={errors.country ? "true" : "false"}
                        id="country"
                    />
                    {errors.country && <div className="invalid-feedback">{errors.country.message}</div>}
                </Col>
            </Row>

            <Row>
                {/* Request Dates */}
                <Col xs={12} lg={6} className="mb-3">
                    <label>Request Date (Required)</label>
                    <input
                        type="date"
                        {...register("requestDateRequired", {
                            required: "Request date is required.",
                            validate: {
                                todayOrAfter: value => {
                                    const today = getTodayDate();
                                    return value >= today || "Date must be today or later.";
                                },
                            },
                        })}
                        className={`form-control ${errors.requestDateRequired ? "is-invalid" : ""}`}
                    />
                    {errors.requestDateRequired && <div className="invalid-feedback">{errors.requestDateRequired.message}</div>}
                </Col>
                <Col xs={12} lg={6} className="mb-3">
                    <label>Request Date (Required)</label>
                    <input
                        type="date"
                        {...register("requestDateOptional", {
                            required: "Optional request date is required.",
                            validate: {
                                afterRequired: value => {
                                    const requiredDate = watch("requestDateRequired");
                                    return new Date(value) > new Date(requiredDate) || "Must be after the required request date.";
                                },
                            },
                        })}
                        className={`form-control ${errors.requestDateOptional ? "is-invalid" : ""}`}
                    />
                    {errors.requestDateOptional && <div className="invalid-feedback">{errors.requestDateOptional.message}</div>}
                </Col>
            </Row>

            <Row>
                <Col xs={12} className="mb-3 d-flex">
                    <input
                        type="checkbox"
                        id="consent"
                        {...register("consent", { required: "Consent is required." })}
                        className={`form-check-input cursor-pointer ${errors.consent ? "is-invalid" : ""}`}
                    />
                    <label htmlFor="consent" className="form-check-label cursor-pointer ps-2">
                        I consent to having this website store my submitted information for contact purposes.
                    </label>
                    {errors.consent && <div className="invalid-feedback d-block">{errors.consent.message}</div>}
                </Col>

                <Col xs={12} className="d-flex justify-content-center">
                    <button type="submit" className="btn btn-warning px-5 py-3 fw-bold text-uppercase" disabled={successMessage}>
                        {isSubmitting ? "Sending..." : "Send Booking Request"}
                    </button>
                </Col>
                {successMessage && (
                    <aside className="mt-3 text-center">
                        <div className="alert alert-success">{successMessage}</div>
                    </aside>
                )}
                <aside className="my-3 fs-10 text-center">
                    RadioAnalyzer will use the information you provide to get in touch with you regarding an online demonstration.
                </aside>
            </Row>

            <ToastContainer position="bottom-right" />
        </form>
    );
};
