import { Link } from "react-router-dom";

export default function CardBlog({ link, state, img, alt, title, caption, variant = "default", className }) {
    const isDefault = variant === "default";
    const cardLink = isDefault ? `` : `w-inherit`;
    const cardClass = `card mb-4 rounded-2 ${isDefault ? `w-100 zoom shadow-1` : `position-relative w-100`} ${className}`;
    const imgClass = isDefault ? "card-img-top rounded-top-2" : "rounded-2";
    const bodyClass = `card-body ${isDefault ? `rounded-2` : `position-absolute bottom-0 start-0 bg-blue-primary w-100 rounded-bottom-2`}`;
    const titleClass = `card-blog-title ${isDefault ? "" : "color-primary-yellow"}`;
    const captionClass = `card-text card-blog-text ${isDefault ? "" : "text-white"}`;

    return (
        <Link to={link} className={`text-decoration-none h-100 d-flex ${cardLink}`} state={state}>
            <div className={cardClass}>
                <div>
                    <img className={`${imgClass} object-fit-cover`} src={img} alt={alt} width="100%" height={445} />
                </div>
                <div className={bodyClass}>
                    <h5 className={titleClass}>{title}</h5>
                    <p className={captionClass}>{caption}</p>
                </div>
                <span className="d-block text-end px-4 pb-2 text-uppercase">read more</span>
            </div>
        </Link>
    );
}
