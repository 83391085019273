export const fadeInUp = {
    initial: { opacity: 0, y: 200 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.8, ease: [0.17, 0.55, 0.55, 1] },
};

export const fadeInLeft = {
    initial: { opacity: 0, x: -200 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.8, ease: "easeOut" },
};

export const fadeInRight = {
    initial: { opacity: 0, x: 200 },
    animate: { opacity: 1, x: 0 },
    transition: { duration: 0.8, ease: "easeOut" },
};

export const fadeInDelay = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.8, delay: 0.5, ease: "easeOut" },
};

export const variantsGroupI = {
    visible: i => ({
        opacity: 1,
        transition: {
            delay: i * 0.1,
        },
    }),
    hidden: { opacity: 0 },
};
