import PartnerQuantumCast from "../assets/OurPartners/quantumCast.png";
import PartnerStreaMonkey from "../assets/OurPartners/streaMonkey.png";
import TritonDigital from "../assets/OurPartners/TritonDigital.png";
import MusicMasterScheduling from "../assets/OurPartners/MusicMasterScheduling.png";

export const OurPartners = [
    { name: "QuantumCast", partner: PartnerQuantumCast },
    { name: "StreaMonkey", partner: PartnerStreaMonkey },
    { name: "TritonDigital", partner: TritonDigital },
    { name: "MusicMasterScheduling", partner: MusicMasterScheduling },
];
