import { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { LinkButtonStyled } from "./GeneralComponents/LinkButtonStyled";

export const ModalComponent = ({ name, img, review }) => {
    const [showModal, setShowModal] = useState(false);
    const modalRef = useRef(null);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        const handleOutsideClick = event => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        const handleEscapeKey = event => {
            if (event.key === "Escape") {
                closeModal();
            }
        };

        if (showModal) {
            document.addEventListener("mousedown", handleOutsideClick);
            document.addEventListener("keydown", handleEscapeKey);
        } else {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.removeEventListener("keydown", handleEscapeKey);
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
            document.removeEventListener("keydown", handleEscapeKey);
        };
    }, [showModal]);

    return (
        <div>
            <LinkButtonStyled onClick={openModal}>Read More...</LinkButtonStyled>

            {showModal && (
                <div className="modal fade show d-block" tabIndex="-1" role="dialog">
                    <div className="modal-dialog" role="document" ref={modalRef}>
                        <div className="modal-content">
                            <div className="modal-header justify-content-between">
                                <h5 className="modal-title">{name}'s Review</h5>
                                <LinkButtonStyled onClick={closeModal}>x</LinkButtonStyled>
                            </div>
                            <div className="modal-body">
                                <div className="d-flex justify-content-center">
                                    <img src={img} alt={`${name}'s review`} className="img-fluid mb-3" />
                                </div>
                                <p>{review}</p>
                            </div>
                            <div className="modal-footer">
                                <LinkButtonStyled onClick={closeModal}>Close</LinkButtonStyled>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && <div className="modal-backdrop fade show"></div>}
        </div>
    );
};
