import { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FormOnlineDemo } from "../components/FormOnlineDemo";
import { dataProducts } from "../data/DataProducts";
import Card from "../components/Card";
import { titleToSlug } from "../helpers/titleToSlug";
import { motion } from "framer-motion";
import { useInView } from "framer-motion";
import { fadeInRight } from "../helpers/animations";
import { GenericAutoSliderSwiper } from "../helpers/GenericAutoSliderSwiper";
import CallToActionAndNewsletter from "../components/CallToActionAndNewsletter";

const ProductSwiper = ({ isTopInView }) => (
    <motion.div
        initial={fadeInRight.initial}
        animate={isTopInView ? fadeInRight.animate : fadeInRight.initial}
        transition={fadeInRight.transition}
        className="px-4">
        <GenericAutoSliderSwiper slides={dataProducts} numberSlides={1}>
            {product => (
                <Card
                    img={product.logo}
                    alt={product.title}
                    title={product.title}
                    description={product.description}
                    link={`${process.env.REACT_APP_WEBSITE_URL}product/${titleToSlug(product.title)}`}
                    className="d-flex flex-column h-100"
                    ImgHeight={150}
                    ImgWidth={150}
                />
            )}
        </GenericAutoSliderSwiper>
    </motion.div>
);

// Main component
export default function BookADemo() {
    const refTop = useRef(null);
    const isTopInView = useInView(refTop, { once: true });
    const swiperRef = useRef(null);

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12} ref={refTop}>
                        <h2 className="fs-9 my-5 text-uppercase text-center">
                            Get a Live Software Tour <br />
                        </h2>
                        <div className="d-flex justify-content-center">
                            <p className="d-block mb-5 text-center max-w-1150">
                                The demo takes place at your own desk live through GoToMeeting, and as soon as your timeslot has been confirmed you will receive
                                a personal mail from the consultant who will carry out the demonstration. The mail contains the link you need to access the
                                meeting.
                                <br />
                                <br />
                                More people than one can use the same link from different locations, so you are welcome to forward and share the link with
                                colleagues so they can join in too.
                                <br />
                                <br />
                                You just need standard internet speed and a headset + microphone to participate – and you can even use your smartphone or tablet
                                if you are not in the office.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <CallToActionAndNewsletter
                title={
                    <div className="text-center">
                        <span>We look forward to showing you our world of</span> <br />
                        <span className="color-primary-yellow">Reconstructed radio analytics</span>
                    </div>
                }
                variant="blue"
            />

            <Container>
                <Row className="align-items-center">
                    <Col xs={12} lg={7} className="mt-md-5">
                        <FormOnlineDemo />
                    </Col>
                    <Col xs={12} lg={5} className="mt-md-5">
                        <ProductSwiper isTopInView={isTopInView} swiperRef={swiperRef} />
                    </Col>
                </Row>
            </Container>
        </>
    );
}
