import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/Logo/LogoWhite.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { LinkButtonStyled } from "./GeneralComponents/LinkButtonStyled";
import { Container } from "react-bootstrap";

export default function Header() {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const location = useLocation();

    const navItems = [
        {
            name: "Products",
            subMenu: [
                { to: "product/radioanalyzer-pro", name: "RadioAnalyzer PRO" },
                { to: "product/music-test", name: "Music Test by RA" },
                { to: "product/data-analysis-and-program-consultancy", name: "Consultancy" },
            ],
        },
        { to: "/book-a-demo", name: "Book a Demo" },
        { to: "/about", name: "About" },
        { to: "/blog", name: "Blog" },
        { to: "/contact", name: "Contact" },
    ];

    const navItemsProducts = [
        { to: "https://musictest.pro/login/?next=/", name: "Login to Music Test" },
        { to: "https://radioanalyzer.com/client/login/", name: "Login to PRO" },
    ];

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleMouseEnter = () => {
        setIsDropdownOpen(true);
    };

    const handleMouseLeave = () => {
        setIsDropdownOpen(false);
    };

    const isActive = path => location.pathname.includes(path);

    return (
        <header className="navbar navbar-expand-lg bg-blue-primary shadow-sm position-fixed w-100 top-0 z-999999">
            <Container>
                <div className="d-flex justify-content-between align-items-center w-100">
                    {/* Logo */}
                    <Link to="/" className={`navbar-brand logo-container`} onClick={() => setIsOpen(false)} style={{ width: "215px", height: "40px" }}>
                        <img className="w-100 h-100 cover" src={Logo} alt="Logo RadioAnalyzer" />
                    </Link>

                    {/* Burger menu for mobile */}
                    <span
                        onClick={toggleMenu}
                        aria-expanded={isOpen}
                        aria-label="Toggle navigation"
                        className="d-lg-none text-white cursor-pointer burger-size">
                        {isOpen ? <FontAwesomeIcon icon={faXmark} className="burger-size" /> : <FontAwesomeIcon icon={faBars} className="burger-size" />}{" "}
                    </span>
                </div>

                <div className={`collapse w-100 navbar-collapse ${isOpen ? "show" : ""}`}>
                    <ul className="navbar-nav ms-auto align-items-lg-center">
                        {navItems.map(item => (
                            <li key={item.name} className="nav-item position-relative text-nowrap">
                                {/* Menu "Products" avec sous-menu */}
                                {item.subMenu ? (
                                    <span
                                        className={`nav-link text-white dropdown-toggle ${isActive("/product") ? "active" : ""}`}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                        style={{ cursor: "pointer" }}>
                                        {item.name}
                                    </span>
                                ) : (
                                    <Link
                                        to={item.to}
                                        className={`nav-link text-white ${location.pathname === item.to ? "active" : ""}`}
                                        onClick={() => setIsOpen(false)}>
                                        {item.name}
                                    </Link>
                                )}

                                {/* Dropdown menu for "Products" */}
                                {item.subMenu && isDropdownOpen && (
                                    <ul className="dropdown-menu show bg-blue-primary" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                        {item.subMenu.map(subItem => (
                                            <li key={subItem.name}>
                                                <Link
                                                    to={subItem.to}
                                                    className={`dropdown-item text-white ${isActive(subItem.to) ? "active" : ""}`}
                                                    onClick={() => setIsOpen(false)}>
                                                    {subItem.name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                        <div className="d-flex">
                            {navItemsProducts.map(item => {
                                return (
                                    <LinkButtonStyled
                                        key={item.name}
                                        target="_blank"
                                        variant="darkblue"
                                        addClass="fs-10 gap-3 text-nowrap me-2 me-lg-0 ms-lg-2 fw-bold"
                                        link={item.to}>
                                        {item.name}
                                    </LinkButtonStyled>
                                );
                            })}
                        </div>
                    </ul>
                </div>
            </Container>
        </header>
    );
}
