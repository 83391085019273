import { Col, Container, Row } from "react-bootstrap";

const Section = ({ title, children }) => (
    <>
        <h2>{title}</h2>
        <p>{children}</p>
    </>
);

const Introduction = () => (
    <>
        <h1>Privacy Policy</h1>
        <p>
            <strong>Updated 14/11/2020</strong>
        </p>
        <p>
            This privacy notice discloses the privacy practices for RadioAnalyzer ApS’s website RadioAnalyzer.com/client. If you are a visitor or a customer of
            RadioAnalyzer ApS’s products, then this Privacy Policy applies to your use of RadioAnalyzer.com/client.
        </p>
        <p>
            This privacy notice applies solely to information collected by this website. RadioAnalyzer ApS respects the privacy rights of our users and
            recognizes the importance of protecting the personal information we collect about you.
        </p>
        <p>The Privacy Notice will notify you of the following:</p>
        <ul>
            <li>What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.</li>
            <li>What choices are available to you regarding the use of your data.</li>
            <li>The security procedures in place to protect the misuse of your information.</li>
            <li>How you can correct any inaccuracies in the information.</li>
        </ul>
    </>
);

// Main component
export default function PrivacyCookiePolicy() {
    return (
        <Container>
            <Row className="mt-5 link-color">
                <Col xs={12}>
                    <Introduction />
                    <Section title="Information Collection, Use, and Sharing">
                        We are the sole owners of the information collected on this site. We only collect information that you voluntarily give us during the
                        setup or other direct contact from you. We will not sell or rent this information to anyone. We will use your information to respond to
                        you, regarding the reason you contacted us. We will not share your information with any third party outside of our organization, other
                        than as necessary to fulfill your request. Unless you ask us not to, we may contact you via email in the future to tell you about
                        specials, new products or services, or changes to this privacy policy.
                    </Section>
                    <Section title="Website Analytics">
                        Whenever you visit or interact with RadioAnalyzer.com, Google Analytics and RadioAnalyzer automatically or passively collect information
                        about how RadioAnalyzer.com/client is accessed and used (“Usage Information”). This also applies to attempted logins.
                        <ul>
                            <li>
                                <strong>Log Files:</strong> Information is logged about your use of RadioAnalyzer.com/client, including your IP-address,
                                username, user ID, device type, browser type and version, operating system and version, ‘Log in’ and ‘last active’ date and
                                time, and page views and items clicked.
                            </li>
                        </ul>
                        This statistical usage data helps us maintain and improve RadioAnalyzer ApS’s services. Google Analytics does not identify individual
                        users or associate your IP address with other data held by Google.
                    </Section>
                    <Section title="Cookies">
                        By using or accessing RadioAnalyzer.com, you are consenting to the use of Cookies.
                        <ul>
                            <li>
                                <strong>How Google Analytics uses cookies:</strong> Google Analytics uses HTTP Cookies to “remember” what a user has done on
                                previous pages and how users interact with the website, helping make your use of the site more convenient.
                            </li>
                        </ul>
                        For more information on how Google Analytics collects and processes data, visit:{" "}
                        <a href="https://policies.google.com/technologies/partner-sites">https://policies.google.com/technologies/partner-sites</a>.
                    </Section>
                    <Section title="Registration">
                        In order to use RadioAnalyzer.com/client, a user or their employer must sign a contract with RadioAnalyzer ApS. The terms and conditions
                        described in the contract between RadioAnalyzer ApS and the Client apply to your access and use of all RadioAnalyzer ApS’s services. The
                        user must provide certain information (such as name and email address) during registration, which is used to contact you about
                        RadioAnalyzer ApS’s services.
                    </Section>
                    <Section title="Your Access to and Control Over Information">
                        You can do the following at any time by contacting us via <a href="mailto:support@radioanalyzer.com">support@radioanalyzer.com</a> or
                        call/WhatsApp: <a href="tel:+4551251240">+45 5125 1240</a>:
                        <ul>
                            <li>See what data we have about you, if any.</li>
                            <li>Change/correct any data we have about you.</li>
                            <li>Have us delete any data we have about you.</li>
                            <li>Express any concern you have about our use of your data.</li>
                        </ul>
                    </Section>
                    <Section title="Security">
                        We take precautions to protect your information. When you submit sensitive information via the website, it is encrypted and transmitted
                        securely. Only employees who need the information to perform a specific job have access to personally identifiable information.
                    </Section>
                    <Section title="Your Consent">
                        By using RadioAnalyzer ApS’s site you accept our privacy policy. If you feel that we are not abiding by this privacy policy, you should
                        contact us immediately via telephone at <a href="tel:+4551251240">+45 5125 1240</a> or via email:{" "}
                        <a href="mailto:support@radioanalyzer.com">support@radioanalyzer.com</a>.
                    </Section>
                </Col>
            </Row>
        </Container>
    );
}
