import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";

export default function Footer() {
    const navItems = [
        { href: "contact", name: "Contact" },
        { href: "about", name: "About" },
        { href: "jobs", name: "Jobs" },
    ];

    const navItemsLegal = [
        { href: "terms-conditions", name: "Terms & Conditions" },
        { href: "privacy-cookie-policy", name: "Privacy & Cookie Policy" },
    ];

    const phoneNumberWithSpaces = "+45 21 30 12 40";

    const cleanPhoneNumber = phoneNumber => {
        return phoneNumber.replace(/\s+/g, "");
    };

    const phoneNumber = cleanPhoneNumber(phoneNumberWithSpaces);

    return (
        <>
            <footer>
                <div className="bg-gray-light py-1"></div>
                <Container>
                    <Row className="d-flex flex-column py-3 flex-md-row">
                        <Col className="d-flex flex-column text-center text-md-start">
                            <span className="fs-2">Tune in to</span>
                            <span className="fs-3 fw-bold">Your Listeners</span>
                            <a className="color-primary-yellow fs-3 fw-bold" href={`tel:${phoneNumber}`} target="_blank" rel="noopener noreferrer">
                                {phoneNumberWithSpaces}
                            </a>
                        </Col>
                        <Col className="d-none d-md-flex flex-md-column mt-md-3 text-center text-md-start">
                            <h6 className="fs-5 mb-2 fw-bold text-decoration-underline">RadioAnalyzer APS</h6>
                            <nav>
                                <ul className="list-unstyled">
                                    {navItems.map(item => (
                                        <li key={item.name}>
                                            <Link
                                                className="text-decoration-none color-primary-yellow-hover"
                                                to={item.href}
                                                aria-label={`Go to ${item.name} page`}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </Col>
                        <Col className="d-flex flex-column mt-3 text-center text-md-start">
                            <h6 className="fs-5 mb-2 fw-bold text-decoration-underline">Support</h6>
                            <a
                                className="color-primary-yellow-hover text-decoration-none"
                                href="mailto:support@radioanalyzer.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Send an email to support@radioanalyzer.com">
                                support@radioanalyzer.com
                            </a>
                            <a
                                className="color-primary-yellow-hover text-decoration-none"
                                href="mailto:tech@radioanalyzer.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Send an email to tech@radioanalyzer.com">
                                tech@radioanalyzer.com
                            </a>
                            <a
                                className="color-primary-yellow-hover text-decoration-none"
                                href="https://www.youtube.com/playlist?list=PLNE_TFo4zkKKqv0y-8w0yg3sQILgKfgRt"
                                target="_blank"
                                rel="noopener noreferrer">
                                Video Manual
                            </a>
                            <a
                                className="color-primary-yellow-hover text-decoration-none"
                                href={`tel:${phoneNumber}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                {phoneNumberWithSpaces}
                            </a>
                        </Col>
                        <Col className="d-flex flex-column mt-3 text-center text-md-start">
                            <h6 className="fs-5 mb-2 fw-bold text-decoration-underline">Contact</h6>
                            <span className="fw-bold">RadioAnalyzer ApS</span>
                            <a
                                className="color-primary-yellow-hover text-decoration-none"
                                href="https://maps.app.goo.gl/CTs5QeYwscW8gNTT6"
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Open the location of RadioAnalyzer in Google Maps">
                                Nytorv 11, 3rd floor
                            </a>
                            <span>9000 Aalborg, DK</span>
                            <span>CVR: 35027149</span>
                        </Col>
                    </Row>
                    {/* Copyright */}
                    <Row>
                        <Col className="pb-2 mt-2 text-center text-md-start">
                            <span className="fw-bold fs-7">
                                Copyright © 2024{" "}
                                <Link className="text-decoration-none color-primary-yellow-hover" to="/" aria-label={`Go to Home page`}>
                                    RadioAnalyzer
                                </Link>
                            </span>
                            <nav>
                                <ul className="list-unstyled d-flex gap-2 justify-content-center justify-content-md-start">
                                    {navItemsLegal.map(item => (
                                        <li key={item.name} className="fs-7">
                                            <Link
                                                className="text-decoration-none color-primary-yellow-hover"
                                                to={item.href}
                                                aria-label={`Go to ${item.name} page`}>
                                                {item.name}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}
