import axios from "axios";

// chargement des données de Strapi
export const strapiLoader = url => async () => {
    const API_KEY = process.env.REACT_APP_STRAPI_API_KEY;
    const IDENTIFIER = process.env.REACT_APP_STRAPI_ADMIN_EMAIL;
    const PASSWORD = process.env.REACT_APP_STRAPI_ADMIN_PASSWORD;

    try {
        const response = await axios.get(
            url,
            {
                identifier: IDENTIFIER,
                password: PASSWORD,
            },
            {
                headers: {
                    Authorization: `Bearer ${API_KEY}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return {
            data: response.data,
            error: null,
            loading: false,
        };
    } catch (error) {
        return {
            data: null,
            error: error.message,
            loading: false,
        };
    }
};

//chargement des articles de blog
export const strapiLoaderBlog = async documentId => {
    const API_KEY = process.env.REACT_APP_STRAPI_API_KEY;
    const IDENTIFIER = process.env.REACT_APP_STRAPI_ADMIN_EMAIL;
    const PASSWORD = process.env.REACT_APP_STRAPI_ADMIN_PASSWORD;

    if (!documentId) {
        return {
            data: null,
            error: "Document ID is missing",
            loading: false,
        };
    }

    const apiUrl = `${process.env.REACT_APP_WEBSITE_URL_BACKEND}/api/blogs/${documentId}/?populate=*`;

    try {
        const response = await axios.get(apiUrl, {
            headers: {
                Authorization: `Bearer ${API_KEY}`,
                "Content-Type": "application/json",
            },
            auth: {
                username: IDENTIFIER,
                password: PASSWORD,
            },
        });

        return {
            data: response.data.data,
            error: null,
            loading: false,
        };
    } catch (error) {
        return {
            data: null,
            error: error.message,
            loading: false,
        };
    }
};
