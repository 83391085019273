import hitradioO3 from "../assets/OurClients/hitradio_o3.png";
import kronehit from "../assets/OurClients/kronehit.png";
import topRadioBelgium from "../assets/OurClients/topRadioBelgium.webp";
import rythme105_7 from "../assets/OurClients/rythme105_7.png";
import virgin from "../assets/OurClients/virginRadio.png";
import move from "../assets/OurClients/move.png";
import dRp3 from "../assets/OurClients/dR_P3.png";
import radioPsr from "../assets/OurClients/radio_psr.png";
import radioBob from "../assets/OurClients/radioBOB.png";
import berlinerRundfunk from "../assets/OurClients/berlinerRundfunk.png";
import klassikRadio from "../assets/OurClients/klassikRadio.png";
import rBBRadioEins from "../assets/OurClients/rBBRadioEins2.png";
import bigFM from "../assets/OurClients/bigFM.png";
import kissFM from "../assets/OurClients/kissFM.svg";
import luxemborg100_7 from "../assets/OurClients/luxemborg.png";
import nPOFunX from "../assets/OurClients/nPOFunX.png";
import skyRadio from "../assets/OurClients/skyRadio.png";
import nrkP3 from "../assets/OurClients/nRKP3.jpg";
import radioZET from "../assets/OurClients/radioZET.png";
import mixMegapol from "../assets/OurClients/mix_megapol.png";
import sRP3 from "../assets/OurClients/sRP3.png";
import capitalFM from "../assets/OurClients/capitalFM.png";
import lbc from "../assets/OurClients/lbc.png";
import heart from "../assets/OurClients/heart.png";
import rFM from "../assets/OurClients/rFM.png";

export const ourClients = [
    { title: "HitRadio Ö3", picture: hitradioO3 },
    { title: "Kronehit", picture: kronehit },
    { title: "TopRadio Belgium", picture: topRadioBelgium },
    { title: "Rythme 105,7", picture: rythme105_7 },
    { title: "Virgin", picture: virgin },
    { title: "Move", picture: move },
    { title: "DRp3", picture: dRp3 },
    { title: "PSR", picture: radioPsr },
    { title: "Radio BOB", picture: radioBob },
    { title: "Berliner Rundfunk", picture: berlinerRundfunk },
    { title: "Klassik Radio", picture: klassikRadio },
    { title: "RBB - Radio Eins", picture: rBBRadioEins },
    { title: "BigFM", picture: bigFM },
    { title: "KissFM", picture: kissFM },
    { title: "100,7 Luxemborg", picture: luxemborg100_7 },
    { title: "NPO FunX", picture: nPOFunX },
    { title: "Sky Radio", picture: skyRadio },
    { title: "NRK P3", picture: nrkP3 },
    { title: "Radio ZET", picture: radioZET },
    { title: "Mix Megapol", picture: mixMegapol },
    { title: "SR P3", picture: sRP3 },
    { title: "Capital FM", picture: capitalFM },
    { title: "LBC", picture: lbc },
    { title: "Heart", picture: heart },
    { title: "RFM", picture: rFM },
];
