import BillDeLisle from "../assets/Authors/BillDeLisle.jpg";
import MikkelBOttesen from "../assets/Authors/Mikkel.jpg";
import AnnaL from "../assets/Team/anna.jpg";
import Emma from "../assets/Team/emma.jpg";
import Dennis from "../assets/Team/dennis.jpg";
import Aleksandrina from "../assets/Team/aleksandrina.jpg";
import Tamas from "../assets/Team/tamas.jpg";
import Vit from "../assets/Team/vit.jpg";
import AnnaOline from "../assets/Team/annaO.jpg";
import katja from "../assets/Team/katja.jpg";
import Oliver from "../assets/Team/oliver.jpg";

export const TeamData = [
    {
        position: "CEO & CO-FOUNDER",
        name: "Mikkel O. Lønstrup",
        linkedin: "https://www.linkedin.com/in/mottesen/",
        picture: MikkelBOttesen,
    },
    {
        position: "CFO & CO-FOUNDER",
        name: "Dennis Søndergaard",
        linkedin: "",
        picture: Dennis,
    },
    {
        position: "COO",
        name: "Bill De Lisle",
        linkedin: "https://www.linkedin.com/in/bill-de-lisle-25a3439b/",
        picture: BillDeLisle,
    },
    {
        position: "GENERAL OFFICE MANAGER",
        name: "Anna Lizák",
        linkedin: "https://www.linkedin.com/in/anna-liz%C3%A1k-4a9057159/",
        picture: AnnaL,
    },
    {
        position: "DATA ENGINEER",
        name: "Vít Vašák",
        linkedin: "https://www.linkedin.com/in/vitvasak/",
        picture: Vit,
    },
    {
        position: "DATA ENGINEER",
        name: "Tamás Kalapács",
        linkedin: "https://www.linkedin.com/in/tamas-kalapacs-77791419b/",
        picture: Tamas,
    },
    {
        position: "SOFTWARE ENGINEER",
        name: "Aleksandrina Krushkova",
        linkedin: "https://www.linkedin.com/in/alex-krushkova/",
        picture: Aleksandrina,
    },
    {
        position: "UI/UX DESIGN INTERN",
        name: "Oliver Esman Pust",
        linkedin: "https://www.linkedin.com/in/oliver-esman-pust/",
        picture: Oliver,
    },
    {
        position: "OFFICE ASSISTANT",
        name: "Emma Katrine Fricke Stigsen",
        linkedin: "https://www.linkedin.com/in/emma-katrine-fricke-stigsen-6536961b8/",
        picture: Emma,
    },
    {
        position: "DATA INTEGRITY CONSULTANT",
        name: "Katja Holst Jensen",
        linkedin: "https://www.linkedin.com/in/katja-holst-jensen-943954195/",
        picture: katja,
    },
    {
        position: "DATA INTEGRITY CONSULTANT",
        name: "Anna Oline Jørgensen",
        linkedin: "https://www.linkedin.com/in/anna-oline-j%C3%B8rgensen-692b72195/",
        picture: AnnaOline,
    },
];
