import { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import Card from "./Card";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { dataProducts } from "../data/DataProducts";
import { GenericAutoSliderSwiper } from "../helpers/GenericAutoSliderSwiper";

export default function ProductsSection() {
    const [showArrows, setShowArrows] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            setShowArrows(window.innerWidth <= 1023);
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Row className="pt-5">
            <div className="d-md-flex">
                <Col xs={12}>
                    <h2 className="fs-9 mb-4 text-uppercase text-center">What is right for your station?</h2>
                    <GenericAutoSliderSwiper slides={dataProducts} numberSlides={1} lgNumberSlides={3} addSpaceForArrow="mx-md-5 p-3" arrows={showArrows}>
                        {item => (
                            <Card
                                img={item.logo}
                                alt={item.alt}
                                title={item.title}
                                featuresList={item.featuresList}
                                link={item.slug}
                                className="d-flex flex-column h-100"
                                ImgHeight={150}
                                ImgWidth={150}
                            />
                        )}
                    </GenericAutoSliderSwiper>

                    <span className="d-block text-center mb-md-3">
                        Start-up user training and technical setup and support is always included. Assist Packages with consultancy options are available with
                        all licenses.
                    </span>
                </Col>
            </div>
        </Row>
    );
}
