import { useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TeamData } from "../data/TeamData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { variantsGroupI } from "../helpers/animations";
import { motion } from "framer-motion";

const TeamIntro = () => (
    <Row>
        <Col xs={12} className="mb-5">
            <h2 className="fs-9 mb-3 pt-5 text-uppercase text-center">Meet our Amazing Team</h2>
            <div className="d-flex justify-content-center">
                <span className="d-block text-center">
                    Our Team is a delightful mix of refined top level experience and raw youthful energy. And we are always looking for new talent that can add
                    another dash of spice and edge to our unit. Meet the guys and girls who make RadioAnalyzer unique – and go to their LinkedIn to get to know
                    them even better.
                </span>
            </div>
        </Col>
    </Row>
);

const TeamMember = ({ member, index }) => {
    const { name, position, linkedin, picture } = member;

    return (
        <Col xs={12} md={6} xl={4} key={name}>
            <motion.div custom={index} initial="hidden" animate="visible" variants={variantsGroupI} className="d-flex flex-column mb-5">
                <div className="d-flex flex-column flex-md-row align-items-center">
                    <img className={`rounded-circle me-2 object-fit-cover ${picture ? "" : "d-none"}`} src={picture} alt={name} width={150} height={150} />
                    <div className="d-flex flex-column text-center text-md-start">
                        <span className="fs-5 fw-bold text-uppercase">{name}</span>
                        <span>{position}</span>
                        {linkedin && (
                            <a href={linkedin} target="_blank" rel="noreferrer" className="color-black">
                                <FontAwesomeIcon icon={faLinkedin} size="2x" />
                            </a>
                        )}
                    </div>
                </div>
            </motion.div>
        </Col>
    );
};

export const Team = () => {
    const refTeam = useRef(null);

    return (
        <Container>
            <TeamIntro />
            <Row ref={refTeam}>
                {TeamData.map((member, i) => (
                    <TeamMember key={member.name} member={member} index={i} />
                ))}
            </Row>
        </Container>
    );
};
