import LogoGreen from "../assets/Logo/LogoGreen.svg";
import LogoYellow from "../assets/Logo/LogoYellow.svg";
import LogoBlue from "../assets/Logo/LogoBlue.svg";

//Icons for RadioAnalyzer Pro
import CompleteAudienceProIconTail from "../assets/Icons/pro/CompleteAudienceProIconTail.svg";
import ComprehensiveRadioAnalyticsProIconTail from "../assets/Icons/pro/ComprehensiveRadioAnalyticsProIconTail.svg";
import OptimizeMusicRotationsProIconTail from "../assets/Icons/pro/OptimizeMusicRotationsProIconTail.svg";
import TrackPerformanceProIconTail from "../assets/Icons/pro/TrackPerformanceProIconTail.svg";
import FullAccessProIconTail from "../assets/Icons/pro/7FullAccessProIconTail.svg";
import FullAccessProIcon from "../assets/Icons/pro/7FullAccessProIcon.svg";
import CompleteAudienceProIcon from "../assets/Icons/pro/CompleteAudienceProIcon.svg";
import ComprehensiveRadioAnalyticsProIcon from "../assets/Icons/pro/ComprehensiveRadioAnalyticsProIcon.svg";
import OptimizeMusicRotationsProIcon from "../assets/Icons/pro/OptimizeMusicRotationsProIcon.svg";
import TrackPerformanceProIcon from "../assets/Icons/pro/TrackPerformanceProIcon.svg";

//Icons for Music Test
import CuttingEdgeMusicTestingTestIconTail from "../assets/Icons/music-test/CuttingEdgeMusicTestingTestIconTail.svg";
import AffordableAlwayOnTestingTestIconTail from "../assets/Icons/music-test/AffordableAlwayOnTestingTestIconTail.svg";
import EnhanceRotationsTestIconTail from "../assets/Icons/music-test/EnhanceRotationsTestIconTail.svg";
import MusicTestingTestIconTail from "../assets/Icons/music-test/MusicTestingTestIconTail.svg";
import UnderstandListernerTestIconTail from "../assets/Icons/music-test/UnderstandListernerTestIconTail.svg";
import CuttingEdgeMusicTestingTestIcon from "../assets/Icons/music-test/CuttingEdgeMusicTestingTestIcon.svg";
import EnhanceRotationsTestIcon from "../assets/Icons/music-test/EnhanceRotationsTestIcon.svg";
import UnderstandListernerTestIcon from "../assets/Icons/music-test/UnderstandListernerTestIcon.svg";

//Icons for Data Analysis
import ExpertLedConsultancyDataIconTail from "../assets/Icons/data-analysis/ExpertLedConsultancyDataIconTail.svg";
import MaximizeContentDataIconTail from "../assets/Icons/data-analysis/MaximizeContentDataIconTail.svg";
import PlaylistStrategyDataIconTail from "../assets/Icons/data-analysis/PlaylistStrategyDataIconTail.svg";
import IncorporateExternalDataDataIconTail from "../assets/Icons/data-analysis/IncorporateExternalDataDataIconTail.svg";
import CustomizedConsultingDataIconTail from "../assets/Icons/data-analysis/CustomizedConsultingDataIconTail.svg";
import ExpertLedConsultancyDataIcon from "../assets/Icons/data-analysis/ExpertLedConsultancyDataIcon.svg";
import MaximizeContentDataIcon from "../assets/Icons/data-analysis/MaximizeContentDataIcon.svg";
import PlaylistStrategyDataIcon from "../assets/Icons/data-analysis/PlaylistStrategyDataIcon.svg";
import IncorporateExternalDataDataIcon from "../assets/Icons/data-analysis/IncorporateExternalDataDataIcon.svg";
import CustomizedConsultingDataIcon from "../assets/Icons/data-analysis/CustomizedConsultingDataIcon.svg";

// Icons for Features
import musicScore from "../assets/Icons/music-test/features/musicScore.svg";
import greatSupport from "../assets/Icons/music-test/features/greatSupport.svg";
import musicTestReports from "../assets/Icons/music-test/features/musicTestReports.svg";
import PlaylistAutopilot from "../assets/Icons/music-test/features/playlistAutopilot.svg";
import songSearch from "../assets/Icons/music-test/features/songSearch.svg";
import trendOverTime from "../assets/Icons/music-test/features/trendOverTime.svg";

import contentPerformance from "../assets/Icons/pro/features/contentPerformance.svg";
import aircheckTool from "../assets/Icons/pro/features/aircheckTool.svg";
import daypartHealth from "../assets/Icons/pro/features/daypartHealth.svg";
import programTracking from "../assets/Icons/pro/features/programTracking.svg";
import hybridHitlist from "../assets/Icons/pro/features/hybridHitlist.svg";

import deepDataAnalysis from "../assets/Icons/data-analysis/features/deepDataAnalysis.svg";
import bespokeReports from "../assets/Icons/data-analysis/features/bespokeReports.svg";
import personalOrOnlineMeetings from "../assets/Icons/data-analysis/features/personalOrOnlineMeeting.svg";
import strategicConsultancy from "../assets/Icons/data-analysis/features/strategicConsultancy.svg";
import talentDevelopment from "../assets/Icons/data-analysis/features/talentDevelopment.svg";
import actionableInsights from "../assets/Icons/data-analysis/features/actioneableInsights.svg";

export const dataProducts = [
    {
        id: 1,
        logo: LogoGreen,
        alt: "logo music test radio analyzer",
        title: "Music Test",
        slogan: "AI-driven and automated best in class music research",
        description: `Music Testing reinvented for today's market and its shifting challenges. Simple, affordable, easy to use, and "always on".`,
        featureSlogan: "Revolutionizing Music Testing for Modern Radio",
        featuresList: [
            { feature: "Music Scores" },
            { feature: "Daypart Health" },
            { feature: "Music Tracking" },
            { feature: "Trend Over Time" },
            { feature: "Deep Song Analytics" },
            { feature: "Music E-Mail Reports" },
            { feature: "Affordable" },
            { feature: "24/7/365 Access" },
            { feature: "All Upgrades Included" },
        ],
        slug: "product/music-test",
        secondSlider: [
            {
                id: 1,
                img: musicScore,
                alt: "",
                title: "Music Testing",
                description: "Test songs easily and affordably, getting real-time feedback on listener preferences to help curate the perfect playlist.",
            },
            {
                id: 2,
                img: PlaylistAutopilot,
                alt: "",
                title: "Playlist Autopilot",
                description:
                    "Automate your playlist rotation with smart suggestions based on current song scores and burn data, ensuring fresh music for your audience.",
            },
            {
                id: 3,
                img: songSearch,
                alt: "",
                title: "Song Search",
                description:
                    "Search for any song in your database and get detailed insights on its performance, helping you make informed decisions for playlist curation.",
            },
            {
                id: 4,
                img: musicTestReports,
                alt: "",
                title: "Music Test Reports",
                description:
                    "Receive automated reports directly to your inbox, giving you easy access to the latest test results and playlist recommendations.",
            },
            {
                id: 5,
                img: trendOverTime,
                alt: "",
                title: "Trend Over Time",
                description: "Track how song performance evolves over time, allowing you to spot emerging hits and maintain a competitive edge in programming.",
            },
            {
                id: 6,
                img: greatSupport,
                alt: "",
                title: "Great Support",
                description: "Get expert assistance whenever you need it, ensuring that your music testing and playlisting are always running smoothly.",
            },
        ],
        productDescription: [
            {
                id: 1,
                title: "Cutting-Edge Music Testing for Today’s Radio",
                description:
                    "Our modern music testing tool provides real-time market analysis and deep song insights, keeping your playlist in tune with audience preferences.",
                image: "",
                isImage: false,
                badge: CuttingEdgeMusicTestingTestIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mt-5 mt-md-0 mb-4 mb-md-0",
            },
            {
                id: 2,
                title: "",
                description: "",
                image: LogoGreen,
                isImage: true,
                badgeArray: [
                    {
                        badge: CuttingEdgeMusicTestingTestIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge1",
                    },
                    {
                        badge: UnderstandListernerTestIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge2",
                    },
                    {
                        badge: MusicTestingTestIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge3",
                    },
                    {
                        badge: AffordableAlwayOnTestingTestIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge4",
                    },
                    {
                        badge: EnhanceRotationsTestIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge5",
                    },
                ],
            },
            {
                id: 3,
                title: "Enhance Rotations with Playlist Autopilot",
                description: "Effortlessly optimize your playlist rotation for the coming weeks with recommendations based on song scores and burn data.",
                image: "",
                isImage: false,
                badge: EnhanceRotationsTestIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
            {
                id: 4,
                title: "Understand Listener Preferences Over Time",
                description: "Track song performance across dayparts and see trends emerge over time, giving you valuable insights into audience behavior.",
                image: "",
                isImage: false,
                badge: UnderstandListernerTestIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mb-4 mb-md-0",
            },
            {
                id: 5,
                title: "Music Testing Paired with Market Trends",
                description:
                    "Combine song performance with real-time market analysis to create playlists that not only hit the right notes but stay ahead of the curve.",
                image: "",
                isImage: false,
                badge: MusicTestingTestIconTail,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "more-space text-md-center mb-4 mb-md-0",
            },
            {
                id: 6,
                title: "Affordable, Always-On Testing",
                description: "With 24/7 access, automated reports, and upgrades included, you get a reliable music testing solution that fits any budget.",
                image: "",
                isImage: false,
                badge: AffordableAlwayOnTestingTestIconTail,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
        ],
        faqData: [
            {
                id: 1,
                question: "Can I calculate more than music?",
                answer: "Yes. You can calculate just about any on-air content you want to. We have set the system up so that music is automatically calculated and analyzed, but if you want to find out if the hourly news or a certain type of content is a hit with your listeners, you can manually calculate it as well. You can even calculate the effect and progress of a commercial campaign or a specific commercial break.",
            },
            {
                id: 2,
                question: "Can my competitors spy on me?",
                answer: "We don’t like to use the word 'spy'. But yes. If your competition has your minute-to-minute data, it would be possible for them to make calculations on your results. In that case it would be up to you to use the data in a more effective way than your opposition. We do – in rare cases – offer exclusivity in a market, subject to negotiations.",
            },
            {
                id: 3,
                question: "How accurate is the validity of the calculations?",
                answer: "We don’t think it would feel right to work with invalid data, so we have gone to great lengths to make sure RadioAnalyzer provides useful and correct information. Using market-standard statistical tables we will always indicate the degree of validity of any calculation of an under– or overperformance of a song or item.",
            },
            {
                id: 4,
                question: "I run a radio station in a country you don’t cover. Can I get RadioAnalyzer?",
                answer: "Yes. Just give us a call and we will look into your area. We just need minute-to-minute data and playlist information, so if you can supply that, we can get started right away. If we need to set up collection of data ourselves, it could take a bit longer to get started.",
            },
            {
                id: 5,
                question: "What kind of data do you need from us?",
                answer: "The beauty is: we use data you already own. No focus groups, no expensive data collection. We need two types of data. Start by giving us access to your playout logs for 100% precision on the music catalogue. This can be as uploads hourly or as a continuous stream we read. Then set up a daily upload of the raw log from your streaming server (or the streaming server of the 3rd party doing your streaming for you). And that’s it. We take care of the rest.",
            },
            {
                id: 6,
                question: "This sounds expensive. What does it cost?",
                answer: "We founded the company because we felt it was about time the use of big data and hard analysis was made available to more radio stations and not just to the largest players in the market. It IS a costly affair to run and maintain the RadioAnalyzer servers, but we always aim to find a price level that is both fair and affordable.",
            },
        ],
    },
    {
        id: 2,
        logo: LogoYellow,
        alt: "RadioAnalyzer Pro",
        title: "RadioAnalyzer Pro",
        slogan: "A professional solution for all your needs",
        description: `Full analytics suite including practical everyday tools, tests of music and content, benchmark dashboard, market surveillance and business intelligence reports`,
        featureSlogan: "Unlock the Full Potential of Your Radio Station",
        featuresList: [
            { feature: "Content Testing" },
            { feature: "Program Tracking" },
            { feature: "Deep Song Analytics" },
            { feature: "Custom Music Reports" },
            { feature: "Deep Market Data" },
            { feature: "Format Comparison" },
            { feature: "Market Overview" },
            { feature: "24/7/365 Access" },
            { feature: "All Upgrades Included" },
        ],
        slug: "product/radioanalyzer-pro",
        secondSlider: [
            {
                id: 1,
                img: musicScore,
                alt: "",
                title: "Music Scores",
                description: "Know how your songs perform by comparing expected vs. actual listener reactions with our unique Song Score system.",
            },
            {
                id: 2,
                img: contentPerformance,
                alt: "",
                title: "Content Testing",
                description:
                    "Harness the power of AI to automate your talk analysis. Break synopsis, automated categories, and score every single talk break based on listener reactions.",
            },
            {
                id: 3,
                img: aircheckTool,
                alt: "",
                title: "Aircheck Tool",
                description:
                    "Review listener reactions to live content with advanced airchecks. Play, download, and analyze how every moment on-air performed.",
            },
            {
                id: 4,
                img: daypartHealth,
                alt: "",
                title: "Daypart Health",
                description:
                    "Get an instant health check on specific time slots with detailed performance insights, helping you optimize programming throughout the day.",
            },
            {
                id: 5,
                img: programTracking,
                alt: "",
                title: "Program Tracking",
                description:
                    "Easily generate graphs to track performance metrics across multiple stations, demographics, or devices, helping you stay ahead of trends.",
            },
            {
                id: 6,
                img: hybridHitlist,
                alt: "",
                title: "Hybrid Hitlist",
                description:
                    "Access a unique weekly chart combining airplay and digital consumption data, offering a complete view of your market’s musical landscape.",
            },
        ],
        productDescription: [
            {
                id: 1,
                title: "Comprehensive Radio Analytics at Your Command",
                description:
                    "Get all the tools your station needs in one suite: music and content testing, market surveillance, and business intelligence to keep you competitive.",
                image: "",
                isImage: false,
                badge: ComprehensiveRadioAnalyticsProIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mt-5 mt-md-0 mb-4 mb-md-0",
            },
            {
                id: 2,
                title: "",
                description: "",
                image: LogoYellow,
                isImage: true,
                badgeArray: [
                    {
                        badge: ComprehensiveRadioAnalyticsProIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge1",
                    },
                    {
                        badge: TrackPerformanceProIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge2",
                    },
                    {
                        badge: CompleteAudienceProIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge3",
                    },
                    {
                        badge: FullAccessProIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge4",
                    },
                    {
                        badge: OptimizeMusicRotationsProIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge5",
                    },
                ],
            },
            {
                id: 3,
                title: "Optimize Music Rotations with Playlist Autopilot",
                description:
                    "Our Playlist Autopilot suggests next week’s rotation based on current song performance and burn scores, ensuring a fresh and engaging playlist every time.",
                image: "",
                isImage: false,
                badge: OptimizeMusicRotationsProIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
            {
                id: 4,
                title: "Track Performance with Content Detection",
                description:
                    "Use speech-to-text technology to monitor every talk break, analyze how each topic performs, and optimize content strategy over time.",
                image: "",
                isImage: false,
                badge: TrackPerformanceProIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mb-4 mb-md-0",
            },
            {
                id: 5,
                title: "Complete Audience and Market Insights",
                description:
                    "Stay ahead with a benchmark dashboard, deep song analytics, and market data that gives you the edge in programming and competitive positioning.",
                image: "",
                isImage: false,
                badge: CompleteAudienceProIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "more-space text-md-center mb-4 mb-md-0",
            },
            {
                id: 6,
                title: "24/7 Full Access, All Upgrades Included",
                description:
                    "Enjoy uninterrupted access to a full suite of tools—music scores, content testing, and more—updated regularly to meet your station's evolving needs.",
                image: "",
                isImage: false,
                badge: FullAccessProIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
        ],
        faqData: [
            {
                id: 1,
                question: "Can I calculate more than music?",
                answer: "Yes. You can calculate just about any on-air content you want to. We have set the system up so that music is automatically calculated and analyzed, but if you want to find out if the hourly news or a certain type of content is a hit with your listeners, you can manually calculate it as well. You can even calculate the effect and progress of a commercial campaign or a specific commercial break.",
            },
            {
                id: 2,
                question: "Can my competitors spy on me?",
                answer: "We don’t like to use the word 'spy'. But yes. If your competition has your minute-to-minute data, it would be possible for them to make calculations on your results. In that case it would be up to you to use the data in a more effective way than your opposition. We do – in rare cases – offer exclusivity in a market, subject to negotiations.",
            },
            {
                id: 3,
                question: "How accurate is the validity of the calculations?",
                answer: "We don’t think it would feel right to work with invalid data, so we have gone to great lengths to make sure RadioAnalyzer provides useful and correct information. Using market-standard statistical tables we will always indicate the degree of validity of any calculation of an under– or overperformance of a song or item.",
            },
            {
                id: 4,
                question: "I run a radio station in a country you don’t cover. Can I get RadioAnalyzer?",
                answer: "Yes. Just give us a call and we will look into your area. We just need minute-to-minute data and playlist information, so if you can supply that, we can get started right away. If we need to set up collection of data ourselves, it could take a bit longer to get started.",
            },
            {
                id: 5,
                question: "What kind of data do you need from us?",
                answer: "The beauty is: we use data you already own. No focus groups, no expensive data collection. We need two types of data. Start by giving us access to your playout logs for 100% precision on the music catalogue. This can be as uploads hourly or as a continuous stream we read. Then set up a daily upload of the raw log from your streaming server (or the streaming server of the 3rd party doing your streaming for you). And that’s it. We take care of the rest.",
            },
            {
                id: 6,
                question: "This sounds expensive. What does it cost?",
                answer: "We founded the company because we felt it was about time the use of big data and hard analysis was made available to more radio stations and not just to the largest players in the market. It IS a costly affair to run and maintain the RadioAnalyzer servers, but we always aim to find a price level that is both fair and affordable.",
            },
        ],
    },
    {
        id: 3,
        logo: LogoBlue,
        alt: "Data Analysis and Program Consultancy",
        title: "Data Analysis and Program Consultancy",
        slogan: "A professional solution for all your needs",
        description: `From the occasional handholding while monitoring your program to full-on strategic consulting and tactical execution - for those that want "more" on top our excellent analysis tools`,
        featureSlogan: "Tailored Expertise for Your Radio Station’s Success",
        featuresList: [
            { feature: "Bespoke Training" },
            { feature: "In-Depth Analysis" },
            { feature: "One-Off Or Regular Training" },
            { feature: "Team Seminars" },
            { feature: "Strategic And Tactical Consulting" },
            { feature: "On-Air Execution" },
            { feature: "Data-Based Airchecking" },
            { feature: "Talent Development" },
            { feature: "Incorporate External Data" },
        ],
        slug: "product/data-analysis-and-program-consultancy",
        secondSlider: [
            {
                id: 1,
                img: deepDataAnalysis,
                alt: "",
                title: "Deep Data Analysis",
                description:
                    "Access in-depth insights beyond the tool’s capabilities, offering a more comprehensive view of your station’s performance and opportunities.",
            },
            {
                id: 2,
                img: bespokeReports,
                alt: "",
                title: "Bespoke Reports",
                description: "Receive custom PowerPoint reports that break down key metrics, trends, and recommendations specific to your station and market.",
            },
            {
                id: 3,
                img: strategicConsultancy,
                alt: "",
                title: "Strategic Consultancy",
                description:
                    "Work with seasoned industry experts who help you develop long-term strategies and tactical solutions to improve your station’s performance.",
            },
            {
                id: 4,
                img: personalOrOnlineMeetings,
                alt: "",
                title: "Personal or Online Meetings",
                description:
                    "Meet with our consultants regularly, whether in-person or online, to review data and get tailored advice on your programming strategy.",
            },
            {
                id: 5,
                img: talentDevelopment,
                alt: "",
                title: "Talent Development",
                description:
                    "Get help with on-air talent development, ensuring that your team continues to engage listeners and grow your audience effectively.",
            },
            {
                id: 6,
                img: actionableInsights,
                alt: "",
                title: "Actionable Insights",
                description: "Transform complex data into easy-to-understand actions with clear recommendations on programming, content, and music strategy.",
            },
        ],
        productDescription: [
            {
                id: 1,
                title: "Expert-Led Consultancy for Radio Excellence",
                description:
                    "Whether it’s content testing, playlist strategy, or full program analysis, our experienced consultants provide the guidance you need to succeed.",
                image: "",
                isImage: false,
                badge: ExpertLedConsultancyDataIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mt-5 mt-md-0 mb-4 mb-md-0",
            },
            {
                id: 2,
                title: "",
                description: "",
                image: LogoBlue,
                isImage: true,

                badgeArray: [
                    {
                        badge: ExpertLedConsultancyDataIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge1",
                    },
                    {
                        badge: PlaylistStrategyDataIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge2",
                    },
                    {
                        badge: CustomizedConsultingDataIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge3",
                    },
                    {
                        badge: IncorporateExternalDataDataIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge4",
                    },
                    {
                        badge: MaximizeContentDataIconTail,
                        classNameBadge: "position-absolute position-badge-start-mobile badge5",
                    },
                ],
            },
            {
                id: 3,
                title: "Maximize Content with Speech-to-Text Detection",
                description:
                    "Track the performance of every talk break with content detection, analyzing what topics work best and how your audience reacts to specific conversations.",
                image: "",
                isImage: false,
                badge: MaximizeContentDataIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
            {
                id: 4,
                title: "Playlist Strategy with Data-Driven Insights",
                description:
                    "Let our experts help optimize your station’s rotations, utilizing Playlist Autopilot and deep song analysis to craft a high-performance playlist strategy.",
                image: "",
                isImage: false,
                badge: PlaylistStrategyDataIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "mb-4 mb-md-0",
            },
            {
                id: 5,
                title: "Customized Consulting Solutions",
                description:
                    "From one-off training sessions to ongoing support, we tailor our consultancy to meet your station’s specific needs, helping you navigate challenges and opportunities.",
                image: "",
                isImage: false,
                badge: CustomizedConsultingDataIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "more-space text-md-center mb-4 mb-md-0",
            },
            {
                id: 6,
                title: "Incorporate External Data for Full Market Understanding",
                description:
                    "Our team helps integrate external data sources into your reports, providing a holistic view of both your station’s performance and the broader market landscape.",
                image: "",
                isImage: false,
                badge: IncorporateExternalDataDataIcon,
                classNameBadge: "position-absolute top-0 position-badge-start-mobile d-md-none",
                additionalStyleParentDiv: "text-md-end mb-4 mb-md-0",
            },
        ],
        faqData: [
            {
                id: 1,
                question: "Can I calculate more than music?",
                answer: "Yes. You can calculate just about any on-air content you want to. We have set the system up so that music is automatically calculated and analyzed, but if you want to find out if the hourly news or a certain type of content is a hit with your listeners, you can manually calculate it as well. You can even calculate the effect and progress of a commercial campaign or a specific commercial break.",
            },
            {
                id: 2,
                question: "Can my competitors spy on me?",
                answer: "We don’t like to use the word 'spy'. But yes. If your competition has your minute-to-minute data, it would be possible for them to make calculations on your results. In that case it would be up to you to use the data in a more effective way than your opposition. We do – in rare cases – offer exclusivity in a market, subject to negotiations.",
            },
            {
                id: 3,
                question: "How accurate is the validity of the calculations?",
                answer: "We don’t think it would feel right to work with invalid data, so we have gone to great lengths to make sure RadioAnalyzer provides useful and correct information. Using market-standard statistical tables we will always indicate the degree of validity of any calculation of an under– or overperformance of a song or item.",
            },
            {
                id: 4,
                question: "I run a radio station in a country you don’t cover. Can I get RadioAnalyzer?",
                answer: "Yes. Just give us a call and we will look into your area. We just need minute-to-minute data and playlist information, so if you can supply that, we can get started right away. If we need to set up collection of data ourselves, it could take a bit longer to get started.",
            },
            {
                id: 5,
                question: "What kind of data do you need from us?",
                answer: "The beauty is: we use data you already own. No focus groups, no expensive data collection. We need two types of data. Start by giving us access to your playout logs for 100% precision on the music catalogue. This can be as uploads hourly or as a continuous stream we read. Then set up a daily upload of the raw log from your streaming server (or the streaming server of the 3rd party doing your streaming for you). And that’s it. We take care of the rest.",
            },
            {
                id: 6,
                question: "This sounds expensive. What does it cost?",
                answer: "We founded the company because we felt it was about time the use of big data and hard analysis was made available to more radio stations and not just to the largest players in the market. It IS a costly affair to run and maintain the RadioAnalyzer servers, but we always aim to find a price level that is both fair and affordable.",
            },
        ],
    },
];
