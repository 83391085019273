import { Col, Container, Row } from "react-bootstrap";
import Banner from "../components/GeneralComponents/Banner";
import { Team } from "../components/Team";
import teamphoto from "../assets/Team/team.png";
import { LinkButtonStyled } from "../components/GeneralComponents/LinkButtonStyled";

const TextSection = ({ children, className = "" }) => <span className={`d-block mb-5 text-center ${className}`}>{children}</span>;

// Main component
export default function About() {
    return (
        <>
            <Container>
                <Row className="mt-5">
                    <Col xs={12}>
                        <h2 className="fs-9 mb-3 text-uppercase text-center">
                            RadioAnalyzer is a radio company <br />
                            <span className="fs-3">Built by radio people for radio people and created out of love for radio</span>
                        </h2>

                        <TextSection>
                            Enabling audio makers to create better content is what we do. Using Big Data, AI, and actual real-time listener behaviour, along
                            with detailed and granular market surveillance.
                        </TextSection>

                        <TextSection>
                            Observing listening behaviour is the fastest and most accurate way to tell if content is working, be it an established benchmark, a
                            new show, or a replacement DJ for your big money maker daypart.
                        </TextSection>

                        <TextSection>
                            Relentlessly innovating Radio and Audio research since 2013, we have consistently grown our portfolio of tools, clients, and success
                            – year by year. Delivering quality insight to radio markets across Europe, North America and Australasia, we’d love to help you find
                            greater success in your market.
                        </TextSection>

                        <TextSection className="d-flex justify-content-center">
                            <LinkButtonStyled variant="dark" link="/contact">
                                <span>Reach out today</span>
                            </LinkButtonStyled>
                        </TextSection>
                    </Col>
                    <Col xs={12}>
                        <Banner img={teamphoto} alt="RadioAnalyzer team" addStyle={{ objectPosition: "top" }} />
                    </Col>
                </Row>
            </Container>
            <Team />
        </>
    );
}
