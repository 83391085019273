import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// Main component
export default function Error() {
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <Container className="text-center align-self-center">
            <Row>
                <Col className="py-5 my-5">
                    <h1>Oh no, something went wrong!</h1>
                    <Button onClick={handleGoBack} className="btn px-5 mb-3 bg-yellow">
                        Go Back
                    </Button>
                    <p>
                        If the issue persists, please contact{" "}
                        <a className="fw-bold color-primary-yellow" href={`mailto:support@radioanalyzer.com?subject=${encodeURIComponent("Page Not Found")}`}>
                            support@radioanalyzer.com
                        </a>
                    </p>
                </Col>
            </Row>
        </Container>
    );
}
