import { useState } from "react";
import { Link } from "react-router-dom";

export const LinkButtonStyled = ({ variant = "dark", icon = "", link, altText = "", children, addClass, onClick, target }) => {
    const [isHovered, setIsHovered] = useState(false);

    let variantStyle = {};
    let iconStyle = {};

    const baseStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
        width: "fit-content",
        transition: "background-color 0.2s ease",
        border: "1px solid var(--color-gray-dark)",
        boxSizing: "border-box",
        textTransform: "uppercase",
        textAlign: "text-center",
        fontWeight: "bold",
    };

    switch (variant) {
        case "dark":
            variantStyle = {
                backgroundColor: isHovered ? "var(--color-yellow-primary)" : "var(--color-gray-dark)",
                color: isHovered ? "var(--color-gray-dark)" : "var(--color-yellow-primary)",
                padding: "5px 15px",
            };
            break;
        case "light":
            variantStyle = {
                backgroundColor: isHovered ? "white" : "var(--color-yellow-primary)",
                color: isHovered ? "black" : "black",
                padding: "5px 15px",
            };
            break;
        case "darkblue":
            variantStyle = {
                backgroundColor: isHovered ? "white" : "var(--color-yellow-primary)",
                color: isHovered ? "var(--color-gray-dark)" : "var(--color-gray-dark)",
                padding: "5px 15px",
                height: "fit-content",
            };
            break;
        case "darkIcon":
            variantStyle = {
                backgroundColor: isHovered ? "var(--color-yellow-primary)" : "var(--color-gray-dark)",
                color: isHovered ? "var(--color-gray-dark)" : "var(--color-yellow-primary)",
                padding: "0px 15px 0px 0px",
            };
            iconStyle = {
                backgroundColor: "var(--color-yellow-primary)",
                borderRadius: "100%",
                marginRight: "5px",
            };
            break;
        case "lightIcon":
            variantStyle = {
                backgroundColor: isHovered ? "var(--color-gray-light)" : "var(--color-yellow-primary)",
                color: isHovered ? "var(--color-gray-dark)" : "var(--color-gray-dark)",
                padding: "0px 15px 0px 0px",
            };
            iconStyle = {
                // backgroundColor: "var(--color-yellow-primary)",
                borderRadius: "100%",
                marginRight: "5px",
            };
            break;
        default:
            variantStyle = {};
            iconStyle = {};
    }

    return (
        <Link
            to={link}
            className={`rounded-2 ${addClass}`}
            style={{
                ...variantStyle,
                ...baseStyles,
            }}
            onClick={onClick}
            target={target}
            type="button"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}>
            {icon ? <img src={icon} alt={altText} style={iconStyle} /> : null}
            {children}
        </Link>
    );
};
