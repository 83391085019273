import { Col, Container, Row } from "react-bootstrap";

const Section = ({ title, children }) => (
    <>
        <h2>{title}</h2>
        <p>{children}</p>
    </>
);

const Introduction = () => (
    <>
        <h1>Terms & Conditions</h1>
        <p>
            <strong>Updated 14/11/2020</strong>
        </p>
        <p>
            Please read these terms and conditions carefully before using the service RadioAnalyzer.com/client operated by RadioAnalyzer ApS. By accessing or
            using the service you agree to be bound by these Terms and Conditions.
        </p>
        <p>
            Your access to and use of RadioAnalyzer.com/client is conditioned on your acceptance of and compliance with the terms listed below. These terms
            apply to all visitors, users and others who access or use RadioAnalyzer.com/client.
        </p>
    </>
);

// Main component
export default function TermsConditions() {
    return (
        <Container>
            <Row className="mt-5 link-color">
                <Col xs={12}>
                    <Introduction />
                    <Section title="Using RadioAnalyzer ApS’s Services">
                        To get a RadioAnalyzer account, you or your employer must sign a contract with RadioAnalyzer ApS. The terms and conditions described in
                        the contract between RadioAnalyzer ApS and the Client will always be valid for the issued users and apply to your access and use of all
                        RadioAnalyzer ApS’s services. Access and usage of RadioAnalyzer ApS’s products is expressly conditioned upon acceptance of the
                        contract’s terms. Do not hesitate to reach out to <a href="mailto:info@radioanalyzer.com">info@radioanalyzer.com</a> if you want to hear
                        more about what we can offer you!
                    </Section>
                    <Section title="Personal Information">
                        We gather, process and use only those personal data that are necessary for the provision of our services or that you have voluntarily
                        made available to us for such purposes. Personal data include all data that contain specific references to your personal or material
                        circumstances such as your name and e-mail address.
                    </Section>
                    <Section title="Duration">
                        The user’s license including binding and duration for RadioAnalyzer ApS’s services is described per Contract agreement between
                        RadioAnalyzer ApS and the Radio Station/Client that issued the user account for RadioAnalyzer.com/client or any other of RadioAnalyzer
                        ApS’ services.
                    </Section>
                    <Section title="User Account Settings">
                        Any form of question regarding the user account setup and/or requests regarding changes must be directed directly to the Client’s
                        contract manager. You are always welcome to contact us via email at{" "}
                        <a href="mailto:support@radioanalyzer.com">support@radioanalyzer.com</a> or via telephone at:{" "}
                        <a href="tel:+4551251240">+45 5125 1240</a>.
                    </Section>
                    <Section title="Complaint">
                        Any form of complaint must be directed directly to us via telephone at <a href="tel:+4551251240">+45 5125 1240</a> or via email:{" "}
                        <a href="mailto:support@radioanalyzer.com">support@radioanalyzer.com</a>.
                    </Section>
                </Col>
            </Row>
        </Container>
    );
}
